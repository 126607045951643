import React from "react";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { useGetPublicPlaylistDataQuery } from "services/graphql";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { useGetUserIsAnonymous } from "modules/selectors";
import { PlaylistPage } from "./PlaylistPage";
import NotFound from "../NotFound";

export default () => {
  const location = useLocation();
  const { playlistId } = useParams<{ playlistId: string }>();
  const { data, loading, error } = useGetPublicPlaylistDataQuery({
    variables: { playlistId },
  });
  const isAnonymous = useGetUserIsAnonymous();

  if (loading) {
    return <LoaderCentered />;
  }

  if (!data || error) {
    return <NotFound />;
  }

  const { owner } = data.playlist;

  // STEEZY created playlists do not have an owner and are public to anonymous users
  if (owner && isAnonymous) {
    return <Redirect to={`/login?redirect=${location.pathname}`} />;
  }

  return <PlaylistPage playlistId={playlistId} />;
};
